<template>
  <b-row>
    <b-col sm="12">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">Friend Request</h4>
        </template>
        <template v-slot:body>
          <ul class="request-list list-inline m-0 p-0">
            <li
              class="d-flex align-items-center"
              v-for="(item, index) in requestList"
              :key="index"
            >
              <div class="user-img img-fluid">
                <img
                  :src="item.apply_user_info.avatar"
                  alt="story-img"
                  class="rounded-circle avatar-40"
                />
              </div>
              <div class="media-support-info ml-3">
                <h6 class="applyClubDesc" :title="item.apply_user_info.nick_name">{{ item.apply_user_info.nick_name }}</h6>
                <p class="mb-0 applyClubDesc w-100">
                  {{ item.type | typeFilter }}
                   <span v-if="item.group_info" class="ml-3" style="color:#50b5ff;">[{{item.group_info.group_name}}]</span>
                </p>
              </div>
              <div class="d-flex align-items-center">
                <a
                  class="mr-3 btn btn-primary rounded"
                  @click="confirmRequest(item.id)"
                  >Confirm</a
                >
                <a
                  @click="delRequest(item.id)"
                  class="mr-3 btn btn-secondary rounded"
                  >Delete Request</a
                >
              </div>
            </li>
          </ul>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>
<script>
import { socialvue } from '@/config/pluginInit'
export default {
  name: 'FriendRequest',
  mounted () {
    socialvue.index()
  },
  data () {
    return {
      requestList: []
    }
  },
  filters: {
    typeFilter (val) {
      return val == 1
        ? 'Friend requests'
        : val == 2
          ? 'Apply to join the club'
          : val == 3
            ? 'Invite to join the club'
            : val == 4
              ? 'Apply for club administrator'
              : ''
    }
  },
  created () {
    this.requestListFunc()
  },
  methods: {
    // 通知列表
    requestListFunc () {
      this.$http.userRequestList().then((res) => {
        this.requestList = res.data.data
      })
    },
    confirmRequest (id) {
      this.$http
        .userRequestConfirm({
          id: id
        })
        .then((res) => {
          if (res.status == 200) {
            this.requestListFunc()
          }
        })
    },
    delRequest (id) {
      this.$http
        .userRequestDel({
          id: id
        })
        .then((res) => {
          if (res.status == 200) {
            this.requestListFunc()
          }
        })
    }
  }
}
</script>
